
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
// 首先的从vue-router中导入useRouter
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'MyHeader',
  props: {
    currentMenuIndex: {
      type: Number,
      default: 0
    }
  },
  setup() {
    // 实例化路由
    const router = useRouter()
    const menuList: any[] = [
      { text: '首页', path: '/' },
      { text: '解决方案', path: 'solution' },
      { text: '硬件产品', path: 'hardware' },
      { text: '软件产品', path: 'software' },
      { text: '关于我们', path: 'about' }
    ]
    const currentHoverItem = ref(0)
    function handleClick(index: number) {
      router.push(menuList[index].path)
    }

    interface ParamsObj {
      path: string;
      type: string;
    }

    function handleJump(item: ParamsObj) {
      router.push({ path: item.path, query: { type: item.type }})
    }

    function handleMouseOver(index: number) {
      currentHoverItem.value = index
    }

    const isTop = ref(true)
    function handleScroll() {
      if ((window.pageYOffset > 0 || document.documentElement.scrollTop > 0 || document.body.scrollTop > 0)) {
        isTop.value = false
      } else {
        isTop.value = true
      }
    }

    // 监听页面滚动事件，滚动了的时候显示下边框
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      menuList, // 菜单列表
      currentHoverItem, // 当前菜单移入项
      handleMouseOver, // 处理下拉框显示
      isTop, // 滚动条是否在页面顶部
      handleClick, // 处理菜单项点击事件
      handleJump // 路由跳转
    }
  }
})
